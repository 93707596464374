class RouteEvent extends EventTarget {
  dispatchEntered() {
    this.dispatchEvent(new Event('entered'));
  }
}

export const routeEvent = new RouteEvent();

export class BindToDOM {
  constructor(renderer) {
    this.renderer = renderer;
    this.render = this.render.bind(this);

    routeEvent.addEventListener('entered', this.render);
  }

  render() {
    this.renderer();
  }
}
