<script>
  import { Swiper, SwiperSlide } from 'swiper/svelte';
  import { Pagination } from 'swiper';

  export let images;
</script>

<Swiper
  grabCursor={true}
  slidesPerView={'auto'}
  spaceBetween={30}
  pagination={{
    clickable: true
  }}
  centeredSlides={false}
  slideToClickedSlide={true}
  modules={[Pagination]}
>
  {#each images as image}
    <SwiperSlide>
      <img src={image} alt="project" />
    </SwiperSlide>
  {/each}
</Swiper>
