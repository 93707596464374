import { BindToDOM } from '../../routeevent';
import Carousel from './carousel.svelte';

new BindToDOM(() => {
  document.querySelectorAll('.carousel').forEach((target) => {
    const props = {
      images: target.getAttribute('data-images').trim().split(',')
    };

    new Carousel({
      target,
      props
    });
  });
});
