import anime from 'animejs';

export const normalize = (value, min, max) => {
  // will return a value between 0 and 1
  return (value - min) / (max - min);
};

export const lerp = (norm, min, max) => {
  return (max - min) * norm + min;
};

export const clamp = (value, min, max) => {
  // force a value into a range
  return value < min ? min : value > max ? max : value;
};

// animejs helper
export const fromTo = (fromConfig, toConfig) => {
  anime({ ...fromConfig, duration: 0 });
  anime(toConfig);
};
